import { HttpOptionsModel } from '@amaris/lib-highway/models';
import { Injectable } from '@angular/core';
import {
  Observable, catchError, map, of,
  switchMap,
  tap,
} from 'rxjs';
import { AuthentificationService, HttpClientService } from '@amaris/lib-highway/services';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from '../../../models/api-response.model';
import { ApiEndpoints } from '../../../enums/api-endpoints.enum';
import { environment } from '../../../../environments/environment';
import { User } from '../../../models/user.model';
import { OAuthMailVerificationResponse } from '../../../models/oAuth.model';
import { DataStoreService } from '../../data-store/data-store.service';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(
    private readonly authentificationService: AuthentificationService,
    private readonly httpClientService: HttpClientService,
    private readonly toastrService: ToastrService,
    private readonly dataStoreService: DataStoreService,
  ) { }

  getConnectedUser(): Observable<ApiResponse<User>> {
    const { sub } = this.authentificationService.getToken();
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Users}/${encodeURI(sub)}`, // environment.apiUrl + ApiEndpoints.Users,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<User>>(options).pipe(
      map((response: ApiResponse<User>) => {
        this.dataStoreService.setConnectedUser(new User(response.data));
        return response;
      }),
      catchError((error: any) => {
        const response: ApiResponse<any> = {
          data: null,
          properties: null,
          error: error.statusText,
          success: error.ok,
          errorCode: error.status,
        };
        return of(response);
      }),
    );
  }

  createConnectedUser(): Observable<ApiResponse<string>> {
    const token = this.authentificationService.getToken();
    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.Users,
      body: {
        sub: token.sub,
      },
      withCredentials: true,
    };
    return this.httpClientService.post<ApiResponse<string>>(options);
  }

  updateConnectedUser(id: string, user: User): Observable<User> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Users}/${id}`,
      body: user,
      withCredentials: true,
    };

    return this.httpClientService.patch<ApiResponse<User>>(options)
      .pipe(
        map((response: ApiResponse<User>) => {
          this.toastrService.success('Vos informations ont été mises à jour avec succès');
          this.getConnectedUser().subscribe();
          return response.data;
        }),
        catchError(() => {
          this.toastrService.error('Une erreur est survenue lors de la mise à jour de vos informations. Veuillez réessayer plus tard.');
          return of(null);
        }),
      );
  }

  switchMedicalPractice(medicalPracticeId: string): Observable<ApiResponse<any>> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Users}/${this.dataStoreService.connectedUser$.value.id}/switch-medical-practice/${medicalPracticeId}`,
      withCredentials: true,
    };

    return this.httpClientService.put<ApiResponse<any>>(options).pipe(
      tap(() => {
        this.authentificationService.clearToken();
        // dev should trigger a reload of location on patients page of profile or what ever
        // to force systeme to reload every data based on new current medical paractive
      }),
    );
  }

  sendVerificationEmail(): Observable<any> {
    // const token = this.authentificationService.getToken();
    // const options: HttpOptionsModel = {
    //   url: `https://plaiad-dev.eu.auth0.com/api/v1/users/${token.sub}/lifecycle/reactivate?sendEmail=true`,
    //   withCredentials: true,
    // };
    // return this.httpClientService.post<ApiResponse<string>>(options);

    const token = this.authentificationService.getToken();
    const options: HttpOptionsModel = {
      url: environment.oAuth0Url + ApiEndpoints.OAuth0MailVerification,
      body: {
        user_id: token.sub,
      },
      withCredentials: true,
    };
    return this.httpClientService.post<OAuthMailVerificationResponse>(options).pipe(
      switchMap((response: OAuthMailVerificationResponse) => {
        if (response.status === 'pending') {
          this.toastrService.success('A verification email is being sent to your email address');
        } else {
          this.toastrService.error('An error occured while sending the verification email');
        }
        const subOptions: HttpOptionsModel = {
          url: `${environment.oAuth0Url + ApiEndpoints.OAuth0MailJobs}/${response.id}`,
          headers: {
            'Access-Control-Allow-Credentials': 'true',
          },
          withCredentials: true,
        };

        return this.httpClientService.get<OAuthMailVerificationResponse>(subOptions).pipe(
          switchMap((response: OAuthMailVerificationResponse) => {
            if (response.status === 'completed') {
              this.toastrService.success('The verification email has been sent');
            } else {
              this.toastrService.error('An error occured while sending the verification email');
            }
            return of(response);
          }),
        );
      }),
    );
  }

  getUsersByMedicalPracticeId(medicalPracticeId: string): Observable<ApiResponse<{ firstname: string, lastname: string, value: string }[]>> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Users}/medicalpractices/${medicalPracticeId}`,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<User[]>>(options).pipe(
      map((response: ApiResponse<User[]>) => {
        const users = response.data.map((user: User) => ({
          firstname: user.firstname,
          lastname: user.lastname,
          value: user.id,
        }));
        return { ...response, data: users };
      }),
      catchError((error: any) => {
        this.toastrService.error('An error occurred while fetching users by medical practice ID');
        return of(null);
      }),
    );
  }
}
